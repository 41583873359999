h1{
    font-family: Satisfy;
    font-size:50px;
    text-align:center;
    color:black;
    padding:1%;
  }
  #gallery{
    -webkit-column-count:4;
    -moz-column-count:4;
    column-count:4;
    padding-top: 60px;
  
    -webkit-column-gap:20px;
    -moz-column-gap:20px;
    column-gap:20px;
  }
  @media (max-width:1200px){
    #gallery{
    -webkit-column-count:3;
    -moz-column-count:3;
    column-count:3;
      
    -webkit-column-gap:20px;
    -moz-column-gap:20px;
    column-gap:20px;
  }
  }
  @media (max-width:800px){
    #gallery{
    -webkit-column-count:2;
    -moz-column-count:2;
    column-count:2;
      
    -webkit-column-gap:20px;
    -moz-column-gap:20px;
    column-gap:20px;
  }
  }
  @media (max-width:600px){
    #gallery{
    -webkit-column-count:1;
    -moz-column-count:1;
    column-count:1;
  }  
  }
  #gallery img,#gallery video {
    width:100%;
    height:auto;
    margin: 4% auto;
    box-shadow:-3px 5px 15px #000;
    cursor: pointer;
    -webkit-transition: all 0.2s;
    transition: all 0.2s;
  }
  .modal-img,.model-vid{
    width:100%;
    height:auto;
  }
  .modal-body{
    padding:0px;
  }

  /* Define a sliding animation */
.slide-in {
  transform: translateX(-100%);
  animation: slideIn 1s ease forwards;
}

@keyframes slideIn {
  to {
    transform: translateX(0);
  }
}
