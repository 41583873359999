.scroll-to-top {
    position: fixed;
    bottom: 30px;
    right: 30px;
    width: 50px;
    height: 50px;
    background-color: #2E6B75;
    color: #fff;
    text-align: center;
    line-height: 50px;
    border-radius: 50%;
    cursor: pointer;
    z-index: 5;
    display: none;
  }
  
  .scroll-to-top.visible {
    display: block;
  }
  