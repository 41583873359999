@media screen and (max-width: 400px) {
  #features {
    padding: 20px;
    width: 100%;
  }
  #about,
  #services,
  #testimonials,
  #team,
  #contact,
  #footer {
    width: 100%;
  }

  #portfolio {
    width: 100%;
  }
}
/* Base styles for larger screens (desktop) */
/* Add your desktop styles here */

/* Media query for screens with a maximum width of 768px (common tablets and small screens) */
@media screen and (min-width: 368px) {
  /* Adjust padding and width for the #features section */
  #features {
    padding: 20px;
    width: 100%;
  }

  /* Set the width of various sections and elements to 100% */
  #about,
  #services,
  #testimonials,
  #team,
  #contact,
  #footer,
  #portfolio {
    width: 100%;
  }

  /* Add specific styles for tablet-sized screens */
  /* For example, you can adjust font sizes or margins here */
}
@media screen and (max-width: 370px) {
 #root {
    width: 150%;
  }
}
