/* testimonials.css */

#testimonials {
  background-color: #ffffff;
  /* padding: 60px 0; */
}

.section-title {
  margin-bottom: 30px;
}

.slider {
  display: flex; /* Use flex display for flexible width */
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden; /* Hide the overflow */
  flex-wrap: wrap;
}

.testimonial {
  flex: 1; /* Let the testimonials adjust their own width */
  display: none;
  text-align: center;
  padding: 20px;
  background-color: #fff;
  /* box-shadow: 0 0 100px rgba(0, 0, 0, 0.2); */
  /* border-radius: 100px; */
  padding-left: 50px;
  padding-right: 50px;
  margin: 20px; /* Add spacing between testimonials */
}

.testimonial.active {
  display: block;
}

.testimonial-image img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin: 0 auto 10px;
}

.testimonial-content p {
  font-size: 16px;
  text-align: justify;
}

.testimonial-meta {
  font-weight: bold;
  margin-top: 10px;
  font-size: 21px;
  color: #000;
}

.slider-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: #2E6B75;
  color: #fff;
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  cursor: pointer;
  font-size: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
}

.slider-button:hover {
  background-color: #2E6B75;
}

.slider-button:first-child {
  left: 3px;
}

.slider-button:last-child {
  right: 3px;
}
