/* moreprojects */
.moreprojects {
	margin: 0 auto;
	padding: 36px;
	margin-top:100px;
  }
  
  .cards {
	display: flex;
	grid-template-columns: auto auto auto; 
	max-width: 1170px;
	margin: 0 auto;
	grid-gap: 9px;
	align-items: center;
	width: 100%;
	flex-wrap: wrap;
  	justify-content: center;
  }
  .card {
	max-width: 100%;
	min-height: 450px;
	/* min-height: 300px; */
	overflow: hidden;
	border-radius: 12px;
	position: relative;
	box-shadow: 0 0 9px #eee;
	transition: all ease .6s;
	width: 300px; /* Adjust the width as needed */
	margin: 10px; /* Adjust the margin as needed */
  }
  .center-card {
	display: flex;
	justify-content: center;
  }
  .pic {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	overflow: hidden;
	background-position: center center;
	background-size: cover;
  }
  .pic img:hover {
	transform: scale(1.2);
  }
  .pic img {
	width: 100%;
  }
  .ttl {
	display: flex;
	flex-flow: column wrap;
	justify-content: flex-end;
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	margin-bottom: -36px;
	text-align: center;
	transition: all ease .3s;
	transform: trasnlateX(12px);
	z-index: 3;
  }
  .ttl:after {
	content: '';
	background: linear-gradient(to top, black, transparent);  
	top:0;right:0;bottom:0;left:0;
	position: absolute;
	z-index: -1;
	margin-bottom: -27px;
  }
  .ttl:hover {
	transform: translateY(-12px);
  }
  
  .ttl .h2 {
	font-family: roboto mono;
	font-size: 21px;
	font-weight: bold;
	/* text-transform: uppercase; */
	position: relative;
	/* background: linear-gradient(to bottom, #ffffff 50%, transparent 100%); */
	-webkit-background-clip: text; /* For better browser support */
	color: white; /* Hide the original text */
  }
  
  /* .ttl .h2:after {
	content: '';
	width: 27px;
	border-top: 3px solid #fff;
	display: block;
	margin: 0 auto;
	margin-top: 21px;
  } */
  
  .ttl .subtitleu a {
	font-family: Roboto;
	width: 60%;
	margin: 0 auto;
	line-height: 22px;
	margin-bottom: 24px;
	color: #fff;
	transition-delay: 0.5s;
  }
  
  .ttl .btn {
	text-decoration: none;
	margin: 0 auto 36px auto;
	width: 100px;
	height: 36px;
	font-weight: 600;
	border-radius: 27px;
	/* border: 1px solid #fff; */
	display: block;
	overflow: hidden;
	transition: all ease .3s;
	background: linear-gradient(to bottom, rgba(255, 255, 255, 1) 30%, rgba(255, 255, 255, 0) 100%);
	background-clip: text;
	-webkit-background-clip: text; /* For better browser support */
	color: white; /* Hide the original text */
  }
  
  .ttl .btn:hover {
	width: 100px;
	background: transparent;
	color: #fff;
  }
  .ttl p{
color: #eee;
  }
  
  @media (min-width: 0) and (max-width: 575px) {
	.cards {
	  grid-template-columns: 100%;
	}
	.card {
	  max-width: 100%;
	}
  }
  @media (min-width: 576px) and (max-width: 767px) {
	.cards {
	  grid-template-columns: auto auto auto;
	}
	.card:nth-child(1) {
	  grid-column: 1 / 3;
	}
	.card:nth-child(2) {
	  grid-column: 3 / 5;
	}
	.card:nth-child(3) {
	  grid-column: 1 / 5;
	}  
  }
  @media (min-width: 768px) and (max-width: 991px) {
	.card:nth-child(1) {
	  grid-column: 1 / 3;
	}
	.card:nth-child(2) {
	  grid-column: 3 / 4;
	}
	.card:nth-child(3) {
	  grid-column: 1 / 4;
	}  
  }
  @media (min-width: 576px) and (max-width: 991px) {
	.cards {
	  grid-template-columns: auto auto;
	}
	.card {
	  max-width: 100%;
	}
  }

  .modal {
	background-color: #2e6b75;
	border-radius: 10px;
	padding: 20px;
	width: 80%;
    height: 70%;
	margin-top: 40px;
	position: fixed;
	top: 50%;
	left: 50%;
	max-height: 80vh; /* Set a maximum height for the modal, adjust as needed */
    overflow-y: auto; /* Enable vertical scrolling if the content exceeds the modal's height */
	transform: translate(-50%, -50%);
	text-align: center;
	z-index: 1000;
	box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  }
  
  .modal h2 {
	font-size: 25px; /* Adjust the font size as needed */
	color: #ffffff;
	font-family: Comfortaa;
  }
  
  .modal p {
	font-size: 1rem; /* Adjust the font size as needed */
  }
  
  .modal button {
	background-color: #007BFF;
	color: #fff;
	border: none;
	padding: 10px 20px;
	margin-top: 10px;
	cursor: pointer;
	border-radius: 5px;
	font-size: 1rem; /* Adjust the font size as needed */
  }
  .sliders{
	width: 640px; /*Same as width of the large image*/
	position: relative;
	/*Instead of height we will use padding*/
	padding-top: 650px; /*That helps bring the labels down*/
	margin: 30px auto;
	
	/*Lets add a shadow*/
	box-shadow: 0 10px 20px -5px rgba(0, 0, 0, 0.75);
}


/*Last thing remaining is to add transitions*/
.sliders>img{
	position: absolute;
	left: 0; top: 0;
	transition: all 0.5s;
	width: 640px;
	height: 650px;
}

.sliders input[name='slide_switch'] {
	display: none;
}

.sliders label {
	/*Lets add some spacing for the thumbnails*/
	margin: 18px 0 0 18px;
	border: 3px solid #999;
	
	float: left;
	cursor: pointer;
	transition: all 0.5s;
	
	/*Default style = low opacity*/
	opacity: 0.6;
}

.sliders label img{
	display: block;
}

/*Time to add the click effects*/
.sliders input[name='slide_switch']:checked+label {
	border-color: #666;
	opacity: 1;
}
/*Clicking any thumbnail now should change its opacity(style)*/
/*Time to work on the main images*/
.sliders input[name='slide_switch'] ~ img {
	opacity: 0;
	transform: scale(1.1);
}
/*That hides all main images at a 110% size
On click the images will be displayed at normal size to complete the effect
*/
.sliders input[name='slide_switch']:checked+label+img {
	opacity: 1;
	transform: scale(1);
}
/*Clicking on any thumbnail now should activate the image related to it*/

/*We are done :)*/

/* Base styles for large screens */
.modal {
	width: 80%;
	height: 70%;
	margin-top: 40px;
  }
  
  .sliders {
	width: 640px;
	padding-top: 650px;
  }
  

  
  /* Media query for tablets */
  @media (max-width: 860px) {
	.modal {
	  width: 90%;
	  padding: 10px;
	}
  
	.sliders {
	  width: 100%;
	  padding-top: 100%; /* Maintain a square aspect ratio */
	}
  
	.sliders > img {
	  width: 100%;
	  height: 100%;
	}
  
	.modal h2 {
	  font-size: 20px;
	  padding-top: 20px;
	}
  
	.modal p {
	  font-size: 0.9rem;
	}
  }
  
  /* Media query for mobile devices */
  @media (max-width: 480px) {
	.modal {
	  width: 100%;
	  height: 100%;
	  padding: 10px;
	}
  
	/* .sliders {
	  padding-top: 140%; 
	} */
  
	.sliders > img {
	  width: 100%;
	  height: 100%;
	}
  
	.modal h2 {
	  font-size: 18px;
	  padding-top: 18px;
	}
  
	.modal p {
	  font-size: 0.8rem;
	}
  }
  
#footer{
	color: #ffffff;
}

#footer a{
	color: #ffffff;
}