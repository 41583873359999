:root {
    --accent: #c7ecee;
    --accentComplimentary: #7ed6df;
}
.grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, 260px);
    gap: 35px;
    padding: 60px 35px;
    justify-content: center;
}
.card {
    border-radius: 35px;
    overflow: hidden;
    height:79%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 465px;
}
.card__top {
    position: relative;
    background-color: var(--accentComplimentary);
    padding: 35px 35px 70px 35px;
    z-index: 0;
    display: flex;
    align-items: flex-start;
}
.card__top:has(.card__figure) {
    aspect-ratio: 1.5;
}
.card__bottom {
    position: relative;
    margin-top: -15px;
    background-color: var(--accent);
    padding: 35px 35px 35px 35px;
    border-radius: 12px;
    display: flex;
    /* gap: 60px; */
    flex-direction: column;
    /* justify-content: space-between; */
    flex-grow: 1;
    z-index: 1;
}
.card__figure {
    position: absolute;
    inset: 0;
    /* mix-blend-mode: lighten; */
    /* opacity: 0.5; */
}
.card__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.card__tag {
    padding: 10px 20px;
    border: 1px solid currentColor;
    border-radius: 2em;
    position: relative;
    z-index: 1;
}
.card__title {
    font-size: 23px;
    text-align: center;
    color: #fff;
}
.card__text {
    font-size: 14px;
    text-align: justify;
    color: #fff;
}
.card--reverse {
    flex-direction: column-reverse;
}
.card--reverse .card__bottom {
    margin-top: 0;
    margin-bottom: -35px;
}
.card--reverse .card__top {
    position: relative;
    padding: 70px 35px 35px 35px;
    align-items: flex-end;
}
.card--primary {
    --accent: #264653;
    --accentComplimentary: #ffffff;
}
.card--secondary {
    --accent: #2A9D8F;
    --accentComplimentary: #f5cd79;
}
.card--tertiary {
    --accent: #E9C46A;
    --accentComplimentary: #ff9ff3;
}
.card--quaternary{
    --accent: #F4A261;
    --accentComplimentary: #ff9ff3;
}
.card--quinary{
    --accent: #E76F51;
    --accentComplimentary: #ff9ff3;
}